/* eslint-disable */
/* tslint:disable */
import { DateString, DatetimeString } from "@uxf/core/date";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: DateString; }
  DateTime: { input: string; output: DatetimeString; }
  JSON: { input: any; output: any; }
  UUID: { input: string; output: string; }
};

export type Area = {
  __typename?: 'Area';
  approach?: Maybe<Scalars['String']['output']>;
  babyFriendly?: Maybe<Scalars['String']['output']>;
  climbingRestrictions?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  drawnRoutes: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<File>;
  location?: Maybe<Gps>;
  name: Scalars['String']['output'];
  orientation?: Maybe<Scalars['String']['output']>;
  pageContent: Scalars['JSON']['output'];
  parking?: Maybe<Gps>;
  published: Scalars['Boolean']['output'];
  sectors: Array<Sector>;
  seoDescription?: Maybe<Scalars['String']['output']>;
  seoName: Scalars['String']['output'];
  shortDescription?: Maybe<Scalars['String']['output']>;
  totalRoutes: Scalars['Int']['output'];
  type: AreaType;
  typeOfProtection?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AreaInput = {
  approach?: InputMaybe<Scalars['String']['input']>;
  babyFriendly?: InputMaybe<Scalars['String']['input']>;
  climbingRestrictions?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUuid?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<GpsInput>;
  name: Scalars['String']['input'];
  orientation?: InputMaybe<Scalars['String']['input']>;
  parking?: InputMaybe<GpsInput>;
  seoDescription?: InputMaybe<Scalars['String']['input']>;
  seoName?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  typeOfProtection?: InputMaybe<Scalars['String']['input']>;
};

export type AreaPreview = {
  __typename?: 'AreaPreview';
  id: Scalars['String']['output'];
  image?: Maybe<File>;
  name: Scalars['String']['output'];
  type?: Maybe<AreaType>;
};

export enum AreaType {
  Boulder = 'boulder',
  Rock = 'rock'
}

export type Attempt = {
  __typename?: 'Attempt';
  area: AreaPreview;
  classification?: Maybe<Classification>;
  date: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  numberOfAttempts?: Maybe<Scalars['Int']['output']>;
  partners?: Maybe<Scalars['String']['output']>;
  public: Scalars['Boolean']['output'];
  route: Route;
  sector: SectorPreview;
  style?: Maybe<AttemptStyle>;
};

export type AttemptInput = {
  classification?: InputMaybe<Scalars['Int']['input']>;
  date: Scalars['Date']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  numberOfAttempts?: InputMaybe<Scalars['Int']['input']>;
  partners?: InputMaybe<Scalars['String']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  style?: InputMaybe<AttemptStyle>;
};

export enum AttemptStyle {
  Af = 'AF',
  Flash = 'FLASH',
  Os = 'OS',
  Pp = 'PP',
  Rc = 'RC',
  Rk = 'RK',
  Rp = 'RP',
  Solo = 'SOLO'
}

export type AuthResult = {
  __typename?: 'AuthResult';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['UUID']['output'];
};

export type Classification = {
  __typename?: 'Classification';
  grade: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  normalizedDifficulty: Scalars['Int']['output'];
  type: ClassificationType;
};

export type ClassificationSection = {
  __typename?: 'ClassificationSection';
  id: Scalars['Int']['output'];
  items: Array<Classification>;
  title: Scalars['String']['output'];
};

export enum ClassificationType {
  Boulder = 'boulder',
  French = 'french',
  Jizerky = 'jizerky',
  Sandrock = 'sandrock',
  Uiaa = 'uiaa',
  Usa = 'usa'
}

export type CreateOrSelectInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Diagram = {
  __typename?: 'Diagram';
  id: Scalars['String']['output'];
  lines: Array<Line>;
  marks: Array<Mark>;
  routeId: Scalars['String']['output'];
  sectorViewId: Scalars['String']['output'];
};

export type DiagramInput = {
  lines: Array<LineInput>;
  marks: Array<MarkInput>;
};

export enum Feature {
  Attempts = 'ATTEMPTS'
}

export type File = {
  __typename?: 'File';
  baseUrl: Scalars['String']['output'];
  extension: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type FileInput = {
  content: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Gps = {
  __typename?: 'Gps';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type GpsInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type HanibalBar = {
  __typename?: 'HanibalBar';
  height: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type Line = {
  __typename?: 'Line';
  points: Array<Point>;
  type: LineType;
};

export type LineInput = {
  points: Array<PointInput>;
  type: LineType;
};

export enum LineType {
  Arrow = 'arrow',
  Dotted = 'dotted',
  Solid = 'solid'
}

export type Mark = {
  __typename?: 'Mark';
  type: MarkType;
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
};

export type MarkInput = {
  type: MarkType;
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};

export enum MarkType {
  Bolt = 'bolt',
  Rappel = 'rappel',
  Ring = 'ring'
}

export type Mutation = {
  __typename?: 'Mutation';
  attemptRemove: Scalars['Boolean']['output'];
  attemptSave: Attempt;
  createArea: Area;
  createDiagram: Diagram;
  createRoute: Route;
  createSector: Sector;
  createSectorView: SectorView;
  forgottenPassword: Scalars['Boolean']['output'];
  login: AuthResult;
  myProfileRemove: Scalars['Boolean']['output'];
  passwordSet: AuthResult;
  paymentCheck: Payment;
  paymentCreate: Payment;
  refreshToken: AuthResult;
  registration: Scalars['Boolean']['output'];
  removeArea: Scalars['Boolean']['output'];
  removeDiagram: Scalars['Boolean']['output'];
  removeRoute: Scalars['Boolean']['output'];
  removeSector: Scalars['Boolean']['output'];
  removeSectorView: Scalars['Boolean']['output'];
  routeCreateSimple: Route;
  updateArea: Area;
  updateDiagram: Diagram;
  updateRoute: Route;
  updateSector: Sector;
  updateSectorView: SectorView;
  uploadImage: File;
};


export type MutationAttemptRemoveArgs = {
  attemptId: Scalars['UUID']['input'];
};


export type MutationAttemptSaveArgs = {
  attemptId: Scalars['UUID']['input'];
  data: AttemptInput;
  routeId: Scalars['String']['input'];
};


export type MutationCreateAreaArgs = {
  data: AreaInput;
};


export type MutationCreateDiagramArgs = {
  data: DiagramInput;
  routeId: Scalars['String']['input'];
  sectorId: Scalars['String']['input'];
  sectorViewId: Scalars['String']['input'];
};


export type MutationCreateRouteArgs = {
  data: RouteInput;
  sectorId: Scalars['String']['input'];
};


export type MutationCreateSectorArgs = {
  areaId: Scalars['String']['input'];
  data: SectorInput;
};


export type MutationCreateSectorViewArgs = {
  areaId: Scalars['String']['input'];
  data: SectorViewInput;
  sectorId: Scalars['String']['input'];
};


export type MutationForgottenPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationPasswordSetArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationPaymentCheckArgs = {
  id: Scalars['String']['input'];
};


export type MutationPaymentCreateArgs = {
  cancelledPaymentOnlyForTesting?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['UUID']['input'];
};


export type MutationRegistrationArgs = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveAreaArgs = {
  areaId: Scalars['String']['input'];
};


export type MutationRemoveDiagramArgs = {
  diagramId: Scalars['String']['input'];
  sectorId: Scalars['String']['input'];
};


export type MutationRemoveRouteArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  routeId: Scalars['String']['input'];
  sectorId: Scalars['String']['input'];
};


export type MutationRemoveSectorArgs = {
  areaId: Scalars['String']['input'];
  sectorId: Scalars['String']['input'];
};


export type MutationRemoveSectorViewArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  sectorViewId: Scalars['String']['input'];
};


export type MutationRouteCreateSimpleArgs = {
  area: CreateOrSelectInput;
  route: RouteInput;
  sector: CreateOrSelectInput;
};


export type MutationUpdateAreaArgs = {
  areaId: Scalars['String']['input'];
  data: AreaInput;
};


export type MutationUpdateDiagramArgs = {
  data: DiagramInput;
  diagramId: Scalars['String']['input'];
  sectorId: Scalars['String']['input'];
};


export type MutationUpdateRouteArgs = {
  data: RouteInput;
  routeId: Scalars['String']['input'];
  sectorId: Scalars['String']['input'];
};


export type MutationUpdateSectorArgs = {
  areaId: Scalars['String']['input'];
  data: SectorInput;
  sectorId: Scalars['String']['input'];
};


export type MutationUpdateSectorViewArgs = {
  data: SectorViewInput;
  sectorViewId: Scalars['String']['input'];
};


export type MutationUploadImageArgs = {
  file: FileInput;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  gwUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  paidTill: Scalars['DateTime']['output'];
  status: PaymentStatus;
};

export enum PaymentStatus {
  Authorized = 'AUTHORIZED',
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Paid = 'PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  PaymentMethodChosen = 'PAYMENT_METHOD_CHOSEN',
  Refunded = 'REFUNDED',
  Skipped = 'SKIPPED',
  Timeouted = 'TIMEOUTED'
}

export type Point = {
  __typename?: 'Point';
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
};

export type PointInput = {
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};

export type Profile = {
  __typename?: 'Profile';
  allowedFeatures: Array<Feature>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paidTill?: Maybe<Scalars['DateTime']['output']>;
  pictureUrl?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  area: Area;
  areaList: Array<Area>;
  areaSearch: Array<AreaPreview>;
  classificationSections: Array<ClassificationSection>;
  hanibalBar?: Maybe<HanibalBar>;
  mapSectors: Array<SearchSector>;
  me?: Maybe<Profile>;
  myAttempts: Array<Attempt>;
  route: Route;
  routeSearch: Array<RouteSearchTicket>;
  sectorSearch: Array<SectorPreview>;
  webArea: WebArea;
  webAreaList: Array<WebArea>;
};


export type QueryAreaArgs = {
  id: Scalars['String']['input'];
};


export type QueryAreaSearchArgs = {
  term: Scalars['String']['input'];
};


export type QueryMapSectorsArgs = {
  nw: GpsInput;
  se: GpsInput;
};


export type QueryMyAttemptsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRouteArgs = {
  route: Scalars['String']['input'];
};


export type QueryRouteSearchArgs = {
  areaTerm: Scalars['String']['input'];
  sectorTerm: Scalars['String']['input'];
  term: Scalars['String']['input'];
};


export type QuerySectorSearchArgs = {
  area: Scalars['String']['input'];
  term: Scalars['String']['input'];
};


export type QueryWebAreaArgs = {
  seoName: Scalars['String']['input'];
};

export type Route = {
  __typename?: 'Route';
  author?: Maybe<Scalars['String']['output']>;
  classification: Classification;
  description?: Maybe<Scalars['String']['output']>;
  firstClimbed?: Maybe<Scalars['Date']['output']>;
  firstClimbedYearOnly?: Maybe<Scalars['Boolean']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numberOfBolts?: Maybe<Scalars['Int']['output']>;
  numberOfRoute?: Maybe<Scalars['Int']['output']>;
  tags: Array<Tag>;
};

export type RouteInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  classification: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  firstClimbed?: InputMaybe<Scalars['Date']['input']>;
  firstClimbedYearOnly?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  numberOfBolts?: InputMaybe<Scalars['Int']['input']>;
  numberOfRoute?: InputMaybe<Scalars['Int']['input']>;
  tags: Array<Tag>;
};

export type RouteSearchTicket = {
  __typename?: 'RouteSearchTicket';
  areaPreview: AreaPreview;
  route: Route;
  sectorPreview: SectorPreview;
};

export type SearchNearMe = {
  __typename?: 'SearchNearMe';
  area: Area;
  areas: Array<Area>;
  sectors: Array<Sector>;
};

export type SearchSector = {
  __typename?: 'SearchSector';
  area: AreaPreview;
  id: Scalars['String']['output'];
  sector: SectorPreview;
};

export type Sector = {
  __typename?: 'Sector';
  approachTime?: Maybe<Scalars['Int']['output']>;
  diagrams: Array<Diagram>;
  id: Scalars['String']['output'];
  image?: Maybe<File>;
  isFree: Scalars['Boolean']['output'];
  location?: Maybe<Gps>;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  routes: Array<Route>;
  sectorViews: Array<SectorView>;
  tags: Array<Tag>;
};

export type SectorInput = {
  approachTime?: InputMaybe<Scalars['Int']['input']>;
  imageUuid?: InputMaybe<Scalars['String']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<GpsInput>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  tags: Array<Tag>;
};

export type SectorPreview = {
  __typename?: 'SectorPreview';
  id: Scalars['String']['output'];
  image?: Maybe<File>;
  location?: Maybe<Gps>;
  name: Scalars['String']['output'];
};

export type SectorView = {
  __typename?: 'SectorView';
  height: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  image: File;
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

export type SectorViewInput = {
  fileUuid: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** A placeholder query used by thecodingmachine/graphqlite when there are no declared subscriptions. */
  dummySubscription?: Maybe<Scalars['String']['output']>;
};

export enum Tag {
  Closed = 'closed',
  Danger = 'danger',
  Excellent = 'excellent',
  Good = 'good',
  NothingMuch = 'nothingMuch',
  Star = 'star',
  SunAfternoon = 'sunAfternoon',
  SunAllDay = 'sunAllDay',
  SunMorning = 'sunMorning',
  SunNever = 'sunNever'
}

export type WebArea = {
  __typename?: 'WebArea';
  approach?: Maybe<Scalars['String']['output']>;
  babyFriendly?: Maybe<Scalars['String']['output']>;
  climbingRestrictions?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  drawnRoutes: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<File>;
  location?: Maybe<Gps>;
  name: Scalars['String']['output'];
  orientation?: Maybe<Scalars['String']['output']>;
  pageContent: Scalars['JSON']['output'];
  parking?: Maybe<Gps>;
  sectors: Array<WebSector>;
  seoName: Scalars['String']['output'];
  totalRoutes: Scalars['Int']['output'];
  type: AreaType;
  typeOfProtection?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WebSector = {
  __typename?: 'WebSector';
  approachTime?: Maybe<Scalars['Int']['output']>;
  diagrams: Array<Diagram>;
  id: Scalars['Int']['output'];
  image?: Maybe<File>;
  location?: Maybe<Gps>;
  name: Scalars['String']['output'];
  routes: Array<Route>;
  sectorViews: Array<SectorView>;
};

export type AreaPreviewFragment = { __typename?: 'Area', id: string, name: string, seoName: string, type: AreaType, approach?: string | null, description?: string | null, babyFriendly?: string | null, climbingRestrictions?: string | null, typeOfProtection?: string | null, orientation?: string | null, totalRoutes: number, drawnRoutes: number, updatedAt?: DatetimeString | null, pageContent: any, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, location?: { __typename?: 'Gps', lat: number, lng: number } | null };

export type AreaFragment = { __typename?: 'Area', id: string, name: string, seoName: string, type: AreaType, approach?: string | null, description?: string | null, babyFriendly?: string | null, climbingRestrictions?: string | null, typeOfProtection?: string | null, orientation?: string | null, totalRoutes: number, drawnRoutes: number, updatedAt?: DatetimeString | null, pageContent: any, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, sectors: Array<{ __typename?: 'Sector', id: string, name: string, approachTime?: number | null, location?: { __typename?: 'Gps', lat: number, lng: number } | null, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, routes: Array<{ __typename?: 'Route', id: string, name: string, author?: string | null, description?: string | null, numberOfRoute?: number | null, firstClimbed?: DateString | null, firstClimbedYearOnly?: boolean | null, tags: Array<Tag>, numberOfBolts?: number | null, height?: number | null, classification: { __typename?: 'Classification', id: number, grade: string, normalizedDifficulty: number, type: ClassificationType } }>, sectorViews: Array<{ __typename?: 'SectorView', id: string, name: string, height: number, width: number, image: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } }>, diagrams: Array<{ __typename?: 'Diagram', id: string, routeId: string, sectorViewId: string, lines: Array<{ __typename?: 'Line', type: LineType, points: Array<{ __typename?: 'Point', x: number, y: number }> }>, marks: Array<{ __typename?: 'Mark', type: MarkType, x: number, y: number }> }> }> };

export type SectorFragment = { __typename?: 'Sector', id: string, name: string, approachTime?: number | null, location?: { __typename?: 'Gps', lat: number, lng: number } | null, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, routes: Array<{ __typename?: 'Route', id: string, name: string, author?: string | null, description?: string | null, numberOfRoute?: number | null, firstClimbed?: DateString | null, firstClimbedYearOnly?: boolean | null, tags: Array<Tag>, numberOfBolts?: number | null, height?: number | null, classification: { __typename?: 'Classification', id: number, grade: string, normalizedDifficulty: number, type: ClassificationType } }>, sectorViews: Array<{ __typename?: 'SectorView', id: string, name: string, height: number, width: number, image: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } }>, diagrams: Array<{ __typename?: 'Diagram', id: string, routeId: string, sectorViewId: string, lines: Array<{ __typename?: 'Line', type: LineType, points: Array<{ __typename?: 'Point', x: number, y: number }> }>, marks: Array<{ __typename?: 'Mark', type: MarkType, x: number, y: number }> }> };

export type PointFragment = { __typename?: 'Point', x: number, y: number };

export type LineFragment = { __typename?: 'Line', type: LineType, points: Array<{ __typename?: 'Point', x: number, y: number }> };

export type MarkFragment = { __typename?: 'Mark', type: MarkType, x: number, y: number };

export type FileFragment = { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string };

export type WebAreaPreviewFragment = { __typename?: 'WebArea', id: number, name: string, seoName: string, type: AreaType, approach?: string | null, description?: string | null, babyFriendly?: string | null, climbingRestrictions?: string | null, typeOfProtection?: string | null, orientation?: string | null, totalRoutes: number, drawnRoutes: number, updatedAt?: DatetimeString | null, pageContent: any, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null };

export type RouteFragment = { __typename?: 'Route', id: string, name: string, author?: string | null, description?: string | null, numberOfRoute?: number | null, firstClimbed?: DateString | null, firstClimbedYearOnly?: boolean | null, tags: Array<Tag>, numberOfBolts?: number | null, height?: number | null, classification: { __typename?: 'Classification', id: number, grade: string, normalizedDifficulty: number, type: ClassificationType } };

export type SectorViewFragment = { __typename?: 'SectorView', id: string, name: string, height: number, width: number, image: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } };

export type DiagramFragment = { __typename?: 'Diagram', id: string, routeId: string, sectorViewId: string, lines: Array<{ __typename?: 'Line', type: LineType, points: Array<{ __typename?: 'Point', x: number, y: number }> }>, marks: Array<{ __typename?: 'Mark', type: MarkType, x: number, y: number }> };

export type WebSectorFragment = { __typename?: 'WebSector', id: number, name: string, approachTime?: number | null, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, routes: Array<{ __typename?: 'Route', id: string, name: string, author?: string | null, description?: string | null, numberOfRoute?: number | null, firstClimbed?: DateString | null, firstClimbedYearOnly?: boolean | null, tags: Array<Tag>, numberOfBolts?: number | null, height?: number | null, classification: { __typename?: 'Classification', id: number, grade: string, normalizedDifficulty: number, type: ClassificationType } }>, sectorViews: Array<{ __typename?: 'SectorView', id: string, name: string, height: number, width: number, image: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } }>, diagrams: Array<{ __typename?: 'Diagram', id: string, routeId: string, sectorViewId: string, lines: Array<{ __typename?: 'Line', type: LineType, points: Array<{ __typename?: 'Point', x: number, y: number }> }>, marks: Array<{ __typename?: 'Mark', type: MarkType, x: number, y: number }> }> };

export type WebAreaFragment = { __typename?: 'WebArea', id: number, name: string, seoName: string, type: AreaType, approach?: string | null, description?: string | null, babyFriendly?: string | null, climbingRestrictions?: string | null, typeOfProtection?: string | null, orientation?: string | null, totalRoutes: number, drawnRoutes: number, updatedAt?: DatetimeString | null, pageContent: any, sectors: Array<{ __typename?: 'WebSector', id: number, name: string, approachTime?: number | null, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, routes: Array<{ __typename?: 'Route', id: string, name: string, author?: string | null, description?: string | null, numberOfRoute?: number | null, firstClimbed?: DateString | null, firstClimbedYearOnly?: boolean | null, tags: Array<Tag>, numberOfBolts?: number | null, height?: number | null, classification: { __typename?: 'Classification', id: number, grade: string, normalizedDifficulty: number, type: ClassificationType } }>, sectorViews: Array<{ __typename?: 'SectorView', id: string, name: string, height: number, width: number, image: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } }>, diagrams: Array<{ __typename?: 'Diagram', id: string, routeId: string, sectorViewId: string, lines: Array<{ __typename?: 'Line', type: LineType, points: Array<{ __typename?: 'Point', x: number, y: number }> }>, marks: Array<{ __typename?: 'Mark', type: MarkType, x: number, y: number }> }> }>, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null };

export type GpsFragment = { __typename?: 'Gps', lat: number, lng: number };

export type PaymentCreateMutationVariables = Exact<{
  cancelledPaymentOnlyForTesting?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PaymentCreateMutation = { __typename?: 'Mutation', paymentCreate: { __typename?: 'Payment', id: string, status: PaymentStatus, gwUrl: string } };

export type PaymentCheckMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PaymentCheckMutation = { __typename?: 'Mutation', paymentCheck: { __typename?: 'Payment', id: string, status: PaymentStatus } };

export type PasswordSetMutationVariables = Exact<{
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type PasswordSetMutation = { __typename?: 'Mutation', passwordSet: { __typename?: 'AuthResult', accessToken: string } };

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResult', accessToken: string } };

export type RegistrationMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type RegistrationMutation = { __typename?: 'Mutation', registration: boolean };

export type AttemptSaveMutationVariables = Exact<{
  attemptId: Scalars['UUID']['input'];
  routeId: Scalars['String']['input'];
  data: AttemptInput;
}>;


export type AttemptSaveMutation = { __typename?: 'Mutation', attemptSave: { __typename?: 'Attempt', id: string } };

export type ProfileFragment = { __typename?: 'Profile', id: string, pictureUrl?: string | null, paidTill?: DatetimeString | null, email: string, allowedFeatures: Array<Feature>, isAdmin: boolean, surname?: string | null, name?: string | null };

export type AreaListQueryVariables = Exact<{ [key: string]: never; }>;


export type AreaListQuery = { __typename?: 'Query', areaList: Array<{ __typename?: 'Area', id: string, name: string, seoName: string, type: AreaType, approach?: string | null, description?: string | null, babyFriendly?: string | null, climbingRestrictions?: string | null, typeOfProtection?: string | null, orientation?: string | null, totalRoutes: number, drawnRoutes: number, updatedAt?: DatetimeString | null, pageContent: any, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, location?: { __typename?: 'Gps', lat: number, lng: number } | null }> };

export type AreaQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AreaQuery = { __typename?: 'Query', area: { __typename?: 'Area', id: string, name: string, seoName: string, type: AreaType, approach?: string | null, description?: string | null, babyFriendly?: string | null, climbingRestrictions?: string | null, typeOfProtection?: string | null, orientation?: string | null, totalRoutes: number, drawnRoutes: number, updatedAt?: DatetimeString | null, pageContent: any, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, sectors: Array<{ __typename?: 'Sector', id: string, name: string, approachTime?: number | null, location?: { __typename?: 'Gps', lat: number, lng: number } | null, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, routes: Array<{ __typename?: 'Route', id: string, name: string, author?: string | null, description?: string | null, numberOfRoute?: number | null, firstClimbed?: DateString | null, firstClimbedYearOnly?: boolean | null, tags: Array<Tag>, numberOfBolts?: number | null, height?: number | null, classification: { __typename?: 'Classification', id: number, grade: string, normalizedDifficulty: number, type: ClassificationType } }>, sectorViews: Array<{ __typename?: 'SectorView', id: string, name: string, height: number, width: number, image: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } }>, diagrams: Array<{ __typename?: 'Diagram', id: string, routeId: string, sectorViewId: string, lines: Array<{ __typename?: 'Line', type: LineType, points: Array<{ __typename?: 'Point', x: number, y: number }> }>, marks: Array<{ __typename?: 'Mark', type: MarkType, x: number, y: number }> }> }> } };

export type WebAreaListQueryVariables = Exact<{ [key: string]: never; }>;


export type WebAreaListQuery = { __typename?: 'Query', areaList: Array<{ __typename?: 'WebArea', id: number, name: string, seoName: string, type: AreaType, approach?: string | null, description?: string | null, babyFriendly?: string | null, climbingRestrictions?: string | null, typeOfProtection?: string | null, orientation?: string | null, totalRoutes: number, drawnRoutes: number, updatedAt?: DatetimeString | null, pageContent: any, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null }> };

export type WebAreaQueryVariables = Exact<{
  seoName: Scalars['String']['input'];
}>;


export type WebAreaQuery = { __typename?: 'Query', area: { __typename?: 'WebArea', id: number, name: string, seoName: string, type: AreaType, approach?: string | null, description?: string | null, babyFriendly?: string | null, climbingRestrictions?: string | null, typeOfProtection?: string | null, orientation?: string | null, totalRoutes: number, drawnRoutes: number, updatedAt?: DatetimeString | null, pageContent: any, sectors: Array<{ __typename?: 'WebSector', id: number, name: string, approachTime?: number | null, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null, routes: Array<{ __typename?: 'Route', id: string, name: string, author?: string | null, description?: string | null, numberOfRoute?: number | null, firstClimbed?: DateString | null, firstClimbedYearOnly?: boolean | null, tags: Array<Tag>, numberOfBolts?: number | null, height?: number | null, classification: { __typename?: 'Classification', id: number, grade: string, normalizedDifficulty: number, type: ClassificationType } }>, sectorViews: Array<{ __typename?: 'SectorView', id: string, name: string, height: number, width: number, image: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } }>, diagrams: Array<{ __typename?: 'Diagram', id: string, routeId: string, sectorViewId: string, lines: Array<{ __typename?: 'Line', type: LineType, points: Array<{ __typename?: 'Point', x: number, y: number }> }>, marks: Array<{ __typename?: 'Mark', type: MarkType, x: number, y: number }> }> }>, image?: { __typename?: 'File', id: string, uuid: string, extension: string, namespace: string } | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'Profile', id: string, pictureUrl?: string | null, paidTill?: DatetimeString | null, email: string, allowedFeatures: Array<Feature>, isAdmin: boolean, surname?: string | null, name?: string | null } | null };

export const FileFragmentDoc = gql`
    fragment File on File {
  id
  uuid
  extension
  namespace
}
    `;
export const GpsFragmentDoc = gql`
    fragment Gps on Gps {
  lat
  lng
}
    `;
export const AreaPreviewFragmentDoc = gql`
    fragment AreaPreview on Area {
  id
  name
  seoName
  type
  approach
  description
  babyFriendly
  climbingRestrictions
  typeOfProtection
  orientation
  totalRoutes
  drawnRoutes
  updatedAt
  pageContent
  image {
    ...File
  }
  location {
    ...Gps
  }
}
    ${FileFragmentDoc}
${GpsFragmentDoc}`;
export const RouteFragmentDoc = gql`
    fragment Route on Route {
  id
  name
  author
  description
  classification {
    id
    grade
    normalizedDifficulty
    type
  }
  numberOfRoute
  firstClimbed
  firstClimbedYearOnly
  tags
  numberOfBolts
  height
}
    `;
export const SectorViewFragmentDoc = gql`
    fragment SectorView on SectorView {
  id
  name
  image {
    ...File
  }
  height
  width
}
    ${FileFragmentDoc}`;
export const PointFragmentDoc = gql`
    fragment Point on Point {
  x
  y
}
    `;
export const LineFragmentDoc = gql`
    fragment Line on Line {
  points {
    ...Point
  }
  type
}
    ${PointFragmentDoc}`;
export const MarkFragmentDoc = gql`
    fragment Mark on Mark {
  type
  x
  y
}
    `;
export const DiagramFragmentDoc = gql`
    fragment Diagram on Diagram {
  id
  routeId
  sectorViewId
  lines {
    ...Line
  }
  marks {
    ...Mark
  }
}
    ${LineFragmentDoc}
${MarkFragmentDoc}`;
export const SectorFragmentDoc = gql`
    fragment Sector on Sector {
  id
  name
  approachTime
  location {
    ...Gps
  }
  image {
    ...File
  }
  routes {
    ...Route
  }
  sectorViews {
    ...SectorView
  }
  diagrams {
    ...Diagram
  }
}
    ${GpsFragmentDoc}
${FileFragmentDoc}
${RouteFragmentDoc}
${SectorViewFragmentDoc}
${DiagramFragmentDoc}`;
export const AreaFragmentDoc = gql`
    fragment Area on Area {
  id
  name
  seoName
  type
  approach
  description
  babyFriendly
  climbingRestrictions
  typeOfProtection
  orientation
  totalRoutes
  drawnRoutes
  updatedAt
  pageContent
  image {
    ...File
  }
  sectors {
    ...Sector
  }
}
    ${FileFragmentDoc}
${SectorFragmentDoc}`;
export const WebAreaPreviewFragmentDoc = gql`
    fragment WebAreaPreview on WebArea {
  id
  name
  seoName
  type
  approach
  description
  babyFriendly
  climbingRestrictions
  typeOfProtection
  orientation
  totalRoutes
  drawnRoutes
  updatedAt
  pageContent
  image {
    ...File
  }
}
    ${FileFragmentDoc}`;
export const WebSectorFragmentDoc = gql`
    fragment WebSector on WebSector {
  id
  name
  approachTime
  image {
    ...File
  }
  routes {
    ...Route
  }
  sectorViews {
    ...SectorView
  }
  diagrams {
    ...Diagram
  }
}
    ${FileFragmentDoc}
${RouteFragmentDoc}
${SectorViewFragmentDoc}
${DiagramFragmentDoc}`;
export const WebAreaFragmentDoc = gql`
    fragment WebArea on WebArea {
  ...WebAreaPreview
  sectors {
    ...WebSector
  }
}
    ${WebAreaPreviewFragmentDoc}
${WebSectorFragmentDoc}`;
export const ProfileFragmentDoc = gql`
    fragment Profile on Profile {
  id
  pictureUrl
  paidTill
  email
  allowedFeatures
  isAdmin
  surname
  name
}
    `;
export const PaymentCreateDocument = gql`
    mutation PaymentCreate($cancelledPaymentOnlyForTesting: Boolean) {
  paymentCreate(cancelledPaymentOnlyForTesting: $cancelledPaymentOnlyForTesting) {
    id
    status
    gwUrl
  }
}
    `;
export type PaymentCreateMutationFn = Apollo.MutationFunction<PaymentCreateMutation, PaymentCreateMutationVariables>;

/**
 * __usePaymentCreateMutation__
 *
 * To run a mutation, you first call `usePaymentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentCreateMutation, { data, loading, error }] = usePaymentCreateMutation({
 *   variables: {
 *      cancelledPaymentOnlyForTesting: // value for 'cancelledPaymentOnlyForTesting'
 *   },
 * });
 */
export function usePaymentCreateMutation(baseOptions?: Apollo.MutationHookOptions<PaymentCreateMutation, PaymentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentCreateMutation, PaymentCreateMutationVariables>(PaymentCreateDocument, options);
      }
export type PaymentCreateMutationHookResult = ReturnType<typeof usePaymentCreateMutation>;
export type PaymentCreateMutationResult = Apollo.MutationResult<PaymentCreateMutation>;
export type PaymentCreateMutationOptions = Apollo.BaseMutationOptions<PaymentCreateMutation, PaymentCreateMutationVariables>;
export const PaymentCheckDocument = gql`
    mutation PaymentCheck($id: String!) {
  paymentCheck(id: $id) {
    id
    status
  }
}
    `;
export type PaymentCheckMutationFn = Apollo.MutationFunction<PaymentCheckMutation, PaymentCheckMutationVariables>;

/**
 * __usePaymentCheckMutation__
 *
 * To run a mutation, you first call `usePaymentCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentCheckMutation, { data, loading, error }] = usePaymentCheckMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentCheckMutation(baseOptions?: Apollo.MutationHookOptions<PaymentCheckMutation, PaymentCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentCheckMutation, PaymentCheckMutationVariables>(PaymentCheckDocument, options);
      }
export type PaymentCheckMutationHookResult = ReturnType<typeof usePaymentCheckMutation>;
export type PaymentCheckMutationResult = Apollo.MutationResult<PaymentCheckMutation>;
export type PaymentCheckMutationOptions = Apollo.BaseMutationOptions<PaymentCheckMutation, PaymentCheckMutationVariables>;
export const PasswordSetDocument = gql`
    mutation PasswordSet($password: String!, $token: String!) {
  passwordSet(password: $password, token: $token) {
    accessToken
  }
}
    `;
export type PasswordSetMutationFn = Apollo.MutationFunction<PasswordSetMutation, PasswordSetMutationVariables>;

/**
 * __usePasswordSetMutation__
 *
 * To run a mutation, you first call `usePasswordSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordSetMutation, { data, loading, error }] = usePasswordSetMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePasswordSetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordSetMutation, PasswordSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordSetMutation, PasswordSetMutationVariables>(PasswordSetDocument, options);
      }
export type PasswordSetMutationHookResult = ReturnType<typeof usePasswordSetMutation>;
export type PasswordSetMutationResult = Apollo.MutationResult<PasswordSetMutation>;
export type PasswordSetMutationOptions = Apollo.BaseMutationOptions<PasswordSetMutation, PasswordSetMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegistrationDocument = gql`
    mutation Registration($email: String!) {
  registration(email: $email)
}
    `;
export type RegistrationMutationFn = Apollo.MutationFunction<RegistrationMutation, RegistrationMutationVariables>;

/**
 * __useRegistrationMutation__
 *
 * To run a mutation, you first call `useRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationMutation, { data, loading, error }] = useRegistrationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<RegistrationMutation, RegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegistrationMutation, RegistrationMutationVariables>(RegistrationDocument, options);
      }
export type RegistrationMutationHookResult = ReturnType<typeof useRegistrationMutation>;
export type RegistrationMutationResult = Apollo.MutationResult<RegistrationMutation>;
export type RegistrationMutationOptions = Apollo.BaseMutationOptions<RegistrationMutation, RegistrationMutationVariables>;
export const AttemptSaveDocument = gql`
    mutation AttemptSave($attemptId: UUID!, $routeId: String!, $data: AttemptInput!) {
  attemptSave(attemptId: $attemptId, routeId: $routeId, data: $data) {
    id
  }
}
    `;
export type AttemptSaveMutationFn = Apollo.MutationFunction<AttemptSaveMutation, AttemptSaveMutationVariables>;

/**
 * __useAttemptSaveMutation__
 *
 * To run a mutation, you first call `useAttemptSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttemptSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attemptSaveMutation, { data, loading, error }] = useAttemptSaveMutation({
 *   variables: {
 *      attemptId: // value for 'attemptId'
 *      routeId: // value for 'routeId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAttemptSaveMutation(baseOptions?: Apollo.MutationHookOptions<AttemptSaveMutation, AttemptSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttemptSaveMutation, AttemptSaveMutationVariables>(AttemptSaveDocument, options);
      }
export type AttemptSaveMutationHookResult = ReturnType<typeof useAttemptSaveMutation>;
export type AttemptSaveMutationResult = Apollo.MutationResult<AttemptSaveMutation>;
export type AttemptSaveMutationOptions = Apollo.BaseMutationOptions<AttemptSaveMutation, AttemptSaveMutationVariables>;
export const AreaListDocument = gql`
    query AreaList {
  areaList {
    ...AreaPreview
  }
}
    ${AreaPreviewFragmentDoc}`;

/**
 * __useAreaListQuery__
 *
 * To run a query within a React component, call `useAreaListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAreaListQuery(baseOptions?: Apollo.QueryHookOptions<AreaListQuery, AreaListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreaListQuery, AreaListQueryVariables>(AreaListDocument, options);
      }
export function useAreaListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreaListQuery, AreaListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreaListQuery, AreaListQueryVariables>(AreaListDocument, options);
        }
export function useAreaListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AreaListQuery, AreaListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AreaListQuery, AreaListQueryVariables>(AreaListDocument, options);
        }
export type AreaListQueryHookResult = ReturnType<typeof useAreaListQuery>;
export type AreaListLazyQueryHookResult = ReturnType<typeof useAreaListLazyQuery>;
export type AreaListSuspenseQueryHookResult = ReturnType<typeof useAreaListSuspenseQuery>;
export type AreaListQueryResult = Apollo.QueryResult<AreaListQuery, AreaListQueryVariables>;
export const AreaDocument = gql`
    query Area($id: String!) {
  area(id: $id) {
    ...Area
  }
}
    ${AreaFragmentDoc}`;

/**
 * __useAreaQuery__
 *
 * To run a query within a React component, call `useAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAreaQuery(baseOptions: Apollo.QueryHookOptions<AreaQuery, AreaQueryVariables> & ({ variables: AreaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreaQuery, AreaQueryVariables>(AreaDocument, options);
      }
export function useAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreaQuery, AreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreaQuery, AreaQueryVariables>(AreaDocument, options);
        }
export function useAreaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AreaQuery, AreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AreaQuery, AreaQueryVariables>(AreaDocument, options);
        }
export type AreaQueryHookResult = ReturnType<typeof useAreaQuery>;
export type AreaLazyQueryHookResult = ReturnType<typeof useAreaLazyQuery>;
export type AreaSuspenseQueryHookResult = ReturnType<typeof useAreaSuspenseQuery>;
export type AreaQueryResult = Apollo.QueryResult<AreaQuery, AreaQueryVariables>;
export const WebAreaListDocument = gql`
    query WebAreaList {
  areaList: webAreaList {
    ...WebAreaPreview
  }
}
    ${WebAreaPreviewFragmentDoc}`;

/**
 * __useWebAreaListQuery__
 *
 * To run a query within a React component, call `useWebAreaListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebAreaListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebAreaListQuery({
 *   variables: {
 *   },
 * });
 */
export function useWebAreaListQuery(baseOptions?: Apollo.QueryHookOptions<WebAreaListQuery, WebAreaListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebAreaListQuery, WebAreaListQueryVariables>(WebAreaListDocument, options);
      }
export function useWebAreaListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebAreaListQuery, WebAreaListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebAreaListQuery, WebAreaListQueryVariables>(WebAreaListDocument, options);
        }
export function useWebAreaListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebAreaListQuery, WebAreaListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebAreaListQuery, WebAreaListQueryVariables>(WebAreaListDocument, options);
        }
export type WebAreaListQueryHookResult = ReturnType<typeof useWebAreaListQuery>;
export type WebAreaListLazyQueryHookResult = ReturnType<typeof useWebAreaListLazyQuery>;
export type WebAreaListSuspenseQueryHookResult = ReturnType<typeof useWebAreaListSuspenseQuery>;
export type WebAreaListQueryResult = Apollo.QueryResult<WebAreaListQuery, WebAreaListQueryVariables>;
export const WebAreaDocument = gql`
    query WebArea($seoName: String!) {
  area: webArea(seoName: $seoName) {
    ...WebArea
  }
}
    ${WebAreaFragmentDoc}`;

/**
 * __useWebAreaQuery__
 *
 * To run a query within a React component, call `useWebAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebAreaQuery({
 *   variables: {
 *      seoName: // value for 'seoName'
 *   },
 * });
 */
export function useWebAreaQuery(baseOptions: Apollo.QueryHookOptions<WebAreaQuery, WebAreaQueryVariables> & ({ variables: WebAreaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebAreaQuery, WebAreaQueryVariables>(WebAreaDocument, options);
      }
export function useWebAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebAreaQuery, WebAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebAreaQuery, WebAreaQueryVariables>(WebAreaDocument, options);
        }
export function useWebAreaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WebAreaQuery, WebAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebAreaQuery, WebAreaQueryVariables>(WebAreaDocument, options);
        }
export type WebAreaQueryHookResult = ReturnType<typeof useWebAreaQuery>;
export type WebAreaLazyQueryHookResult = ReturnType<typeof useWebAreaLazyQuery>;
export type WebAreaSuspenseQueryHookResult = ReturnType<typeof useWebAreaSuspenseQuery>;
export type WebAreaQueryResult = Apollo.QueryResult<WebAreaQuery, WebAreaQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;